import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from '../../../../src/app/pages/dashboard/dashboard.service';
import { AppConfig } from '../../../../src/app/app.config';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RechargeService } from '../../../../src/app/pages/recharge/recharge.service';
import { AuthService } from '../../../../src/app/auth/auth-service';

@Component({
  selector: 'app-payment-link',
  templateUrl: './payment-link.component.html',
  styleUrls: ['./payment-link.component.scss']
})
export class PaymentLinkComponent implements OnInit {
  @ViewChild('rePaymodalPayment', { static: true }) rePaymodalPaymentRef: ElementRef;
  backEndImage:string;
  BackendFinalurl:string;
  userFullName:any;
  isDisable:boolean=true;
  IsNumeric:boolean=false;
  partnerId:number;
  storeVal:any;
  paymentGatewayId;
  positveDueAmount:any;
  positveDueAmountConst:any;
  paymentLinkForm = new FormGroup({
    firstName: new FormControl(),  
    lastName: new FormControl(),    
    custId:new FormControl(),
    partnerId:new FormControl(),
    dueAmount:new FormControl(), 
    username: new FormControl(), 
    amountType:new FormControl(),
    finalamount:new FormControl('',[Validators.pattern("^[0-9]*$")])
  });
  myObject:any;
  paymentGatewayList:[]=[];
  defualtPaymentGateway:any;
  constructor(
    private appConfig: AppConfig,
    private route:ActivatedRoute,
    private DashboardService:DashboardService,
    private toaster:ToastrService,
    private router:Router,
    private modalService:NgbModal,
    private rechargeService:RechargeService,
    private auth:AuthService
  ) { 
    this.backEndImage=this.appConfig.config.baseURLApi;
    this.BackendFinalurl=this.backEndImage.split('/')[2];
  }

  ngOnInit() {
    let userName = this.route.snapshot.paramMap.get('user');
    this.getDataByUserName(userName);
  }
  
  openPopup() {
    let apiInputedData=this.paymentLinkForm.value;
    delete apiInputedData.amountType;
    
    if (this.paymentLinkForm.valid === true) {
      this.getPaymentQateway() //new call
      this.open(this.rePaymodalPaymentRef)
    }
  }
  
  open(content) {
    this.modalService.open(content)
  }

  getDataByUserName(uname:any){
  
    this.DashboardService.getCustomerDataFromUserNameLink(uname).subscribe((res)=>{
         if(res && res.responseCode===200){
          this.userFullName = res.data.username; 
          this.positveDueAmount=null;
           for(let key of Object.keys(res.data)){
            this.paymentLinkForm.get(key).setValue(res.data[key])
           }
           this.positveDueAmount=Math.abs(res.data.dueAmount);
           this.positveDueAmountConst = Math.abs(res.data.dueAmount);
           this.partnerId=res.data.partnerId; //set for Get PaymentGateway List
          }else{
            this.toaster.error(res.responseMessage)
          }
        },err=>{
          this.toaster.error(err.error.status + " "+err.error.ERROR)
        }
    )
  }
  
  cancelForm(){
    this.auth.logout();
    this.router.navigate(['/login'])

  }
  amountPayType(val){
      this.storeVal=val
      if(val==='totalAmount'){
         this.isDisable=true;
         this.IsNumeric=false;
         this.paymentLinkForm.get('amountType').setValue(val);
         this.paymentLinkForm.get('dueAmount').setValue(this.positveDueAmountConst);
         this.paymentLinkForm.get('dueAmount').updateValueAndValidity();
         
       }
      if(val==='Others'){
        this.isDisable=false;
        this.paymentLinkForm.get('dueAmount').setValue(null);
        this.paymentLinkForm.get('dueAmount').updateValueAndValidity();
        this.paymentLinkForm.get('amountType').setValue(val);

       }
  }
  
  //**Allow Only Numeric Value */
  NumbersOnlyAllow(event){
    var charCode = (event.which) ? event.which : event.keyCode;
      if ((charCode < 48 || charCode > 57) && this.storeVal ==='Others') {
        event.preventDefault();
        this.IsNumeric=true;
        return false;
      } else {
        this.IsNumeric=false;
        return true;
      }
  }
  getPaymentQateway() {
    
    this.rechargeService.getPaymentGatewayByPartner(this.partnerId).subscribe(response => {
      this.paymentGatewayList = response.dataList;
      
      response.dataList.forEach(element => {
        
        if (element.defaultpgflag === "Yes") {
          this.defualtPaymentGateway = element.paymentGateWayId;
          this.eventCheck(element);
        }
      });
    })
  }
  
  eventCheck(paymentGateway) {

    if (paymentGateway) {
      this.paymentGatewayId = paymentGateway.paymentGateWayId;
    }
  }

  insertPlaceOrderMe() {
    
    if(this.paymentGatewayId == null){
      this.toaster.error('Please Select One Payment Gateway');
      
    }else{
      let is_balance_used;
      let placeOrder = {
        "custId": this.paymentLinkForm.value.custId,
        "entityid": null,
        "pgid": this.paymentGatewayId,
        "ordertype": "repayment",
        "finalamount": this.positveDueAmount,
        "is_balance_used": is_balance_used,
        "is_settled": false,
        "orderdesc": "dsa",
        "partnerId": 0,
        "taxamount": 0,
        "purchase_type":null
      };
      this.rechargeService.insertPlaceOrder(placeOrder).subscribe((result) => {
        
        if (result) {
          if (result.responseCode == 200 && result.data) {
            this.modalService.dismissAll();
            if (result.data.amount === 0 && result.data.isPaymentRemaining === true) {
              this.router.navigate(['recharge/payment-status'], { queryParams: { txnid: result.data.pgDetails.txnid } });
            } else {
              this.router.navigate(['redirecttopayment/', result.data.uniqueId]);
            }
          }else{
            this.toaster.error(result.responseMessage);
          }
        }
        
      }, err => {
        this.toaster.error(err.error.status + " " + err.error.ERROR);
      });
    }
  
}

}
