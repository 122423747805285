import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { timer } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from './service.service';
import { FormControl, FormGroup } from '@angular/forms';
import { RechargeService } from '../../pages/recharge/recharge.service';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ResponseCode } from '../../core/constant/response-code';
import { AppConfig } from '../../../../src/app/app.config';

@Component({
  selector: 'app-payment-midpage',
  templateUrl: './payment-midpage.component.html',
  styleUrls: ['./payment-midpage.component.scss']
})
export class PaymentMidpageComponent implements OnInit,OnDestroy {
  @ViewChild('hiddenPayuForm',{static: false}) hiddenPayuFormElement;
  timeLeft: number = 5;
  time:number =5
  redirectotUrl:any
  subscribeTimer: any;
  interval;
  uniqueId:any
  paymentGatewayFormGroup:FormGroup;
  public payuformSubmitUrl:string = '';
  urlcallflag:boolean=true
  paymentStatus:string;
  pgResStatus:string;
  customerLogo:any;
  transId:any;
  orderCheck:any = false;
  backEndImage:string;
  BackendFinalurl:string;
  formTemplate:any = [];
  constructor(
    
    private router : Router,
    private route : ActivatedRoute,
    private paymentService : ServiceService,
    private rechargeService : RechargeService,
    private tosterService:ToastrService,
    private appConfig: AppConfig
  ) {
    this.backEndImage=this.appConfig.config.baseURLApi;
    // this.backEndImage=this.appConfig.imgbaseURL;
    this.BackendFinalurl=this.backEndImage.split('/')[2];
    
   }

  ngOnInit() {
  window.history.forward() 
    // window.location.reload();
    this.startTimer()
    this.rechargeService.getCustomerlogo().subscribe((res)=>{
      this.customerLogo = environment.baseurlDev + res.data
          })
setTimeout(() => {
  this.urlcallflag= false
}, 1000);

 let transId = this.route.snapshot.paramMap.get('id')
 this.transId = transId
    if(transId){
      this.getDataByTransaction(transId)
    }
    
  }
 
  getDataByTransaction(transId:any){
    
this.paymentService.getDataByTransId(transId).subscribe((res)=>{
  
    this.uniqueId = res.data.uniqueId;
    this.redirectotUrl = res.data.submitUrl
   
    this.pgResStatus = res.data.pgResStatus
    this.paymentStatus = res.data.paymentStatus;
    if(res.data.paymentStatus == 'Pending' && !res.data.pgResStatus ){
      this.createDynamicFormByResponse(res);
    }
    else{
      this.paymentStatus = res.data.paymentStatus;
      setTimeout(() => {
        this.router.navigate(['/dashboard'])
      }, 1000);
    
    }
  
 
})
  }

  oberserableTimer() {
    const source = timer(1000, 2000);
    const abc = source.subscribe(val => {
      //console.log(val, '-');
      this.subscribeTimer = this.timeLeft - val;
    });
  }

  startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } 
      if(this.timeLeft ===  0 && this.urlcallflag === false){
       
        return;
      }
    },1000)
  
  }


  createDynamicFormByResponse(res:any){
         
    this.payuformSubmitUrl = res.data.submitUrl;
    
    let formGroup = {};
    for (let key in res.data.pgDetails) {
      let formControl = {name:'',value:''};
      if (res.data.pgDetails.hasOwnProperty(key)) {
        formControl.name = key;
        formControl.value = res.data.pgDetails[key];
        this.formTemplate.push(formControl);
          
      }
    }

    this.formTemplate.forEach( obj => {
      formGroup[obj.name] = new FormControl(obj.value);
    });

   this.paymentGatewayFormGroup = new FormGroup(formGroup);
   
   if(this.payuformSubmitUrl !=='') {
     this.paymentService.checkOrder(this.transId).subscribe((res)=>{
       
      if(res.responseCode === ResponseCode.OK){
        
        this.executePayu();
      }
      else{
       this.orderCheck = true
      }
     });
    
    
  
    
   }
}


executePayu() {

  setTimeout(() => {
    this.urlcallflag = true
    this.hiddenPayuFormElement.nativeElement.submit();
  },1000);
}
onClickexecutePayu() {
  
  this.urlcallflag = true
    this.hiddenPayuFormElement.nativeElement.submit();
}
redirectToDashboard(){
  this.router.navigate(['/dashboard'])
}
ngOnDestroy(): void {
    this.orderCheck = false
}
}
