import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

// URL to web api
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class RechargeService {
  constructor(private http: HttpClient) {}

  changeSelfcarePass(data: any): Observable<any> {
    const post_url = baseApiUrl + 'subscriber/self/updatePassword';
    return this.http.post<any>(post_url, data, httpOptions)
      .pipe(map(res => res),
      catchError((error: any) => {
        return throwError(error);
      })
      );
  }
  getCustomerlogo(){
    const get_url = baseApiUrl + 'subscriber/getLogoPath';
    return this.http.get<any>(get_url,{ headers: httpOptions.headers})
   }

  getExpiredPlanList(custId : any): Observable<any> {
    const get_url = baseApiUrl + 'subscriber/getExpiredPlanList/' + custId;
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  checkeligiblity(custId){
    const get_url = baseApiUrl + 'subscriber/checkEligibilityAddon/' + custId;
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers })
  }

  getAllSubcriberPlanByPartnerId(data: any): Observable<any> {
    const post_url = baseApiUrl + 'subscriber/plan/NewBypartner';
    return this.http.post<any>(post_url, data, httpOptions)
      .pipe(map(res => res),
      catchError((error: any) => {
        return throwError(error);
      })
      );
  }

  getPaymentGatewayList(): Observable<any> {
    const get_url = baseApiUrl + 'subscriber/getPGForUsers';
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  
  insertPlaceOrder(placeOrder: any): Observable<any> {
   // const post_url = baseApiUrl + 'order/place';
    return this.http.post<any>('subscriber/order/place', placeOrder, httpOptions).pipe(
      map(data => data),
      catchError((error: any) => {
          return throwError(error);
      })
    );
 }

 getPurchaseDetailStatusByTransactionId(txnId): Observable<any> {
    const get_url = baseApiUrl + 'subscriber/txnStatus/' + txnId;
    return this.http.get<any>(get_url,{ headers: httpOptions.headers}).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  getTaxDetailByPlan(data: any): Observable<any> {
    const post_url = baseApiUrl + 'subscriber/getTaxDetails/byPlan';
    return this.http.post<any>(post_url, data, httpOptions)
      .pipe(map(res => res),
      catchError((error: any) => {
        return throwError(error);
      })
      );
  }

  getCommonListType(type): Observable<any> {
    const get_url = baseApiUrl + 'subscriber/commonList/generic/'+type;
    return this.http.get<any>(get_url);
  }
  getActivePlanList(custId : any): Observable<any> {
    const get_url = baseApiUrl + 'subscriber/getActivePlanList/' + custId;
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  getWalletBalance(){
    const get_url=baseApiUrl+'subscriber/checkWalletBalanceUse';
    return this.http.get<any>(get_url);
  }
  getPaymentGatewayByPartner(partnerId){
    const get_url=baseApiUrl+'subscriber/getPartnerPGConfigByPartnerId/'+partnerId;
    return this.http.get<any>(get_url);
  }
  getorderTypeList(): Observable<any> {
    const get_url = baseApiUrl + 'subscriber/commonList/generic/purchaseHistoryCustomer';
    return this.http.get<any>(get_url);
  }

  checkForSetUpBox(id){
    const get_url=baseApiUrl+'subscriber/checkForSetUpBox/' + id;
    return this.http.get<any>(get_url);
  }
  
}