import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseData } from '../../core/modal/base-save-update-response';

// URL to web api
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor(private http: HttpClient) { }
  
  getDataByTransId(transId):any{
    const get_url = baseApiUrl + 'subscriber/order/ByTransId/' + transId;
    return this.http.get(get_url, httpOptions);
  }
  checkOrder(transId):Observable<ResponseData<any>>{
    const get_url = baseApiUrl + 'subscriber/order/checkOrder/' + transId;
    return this.http.get<ResponseData<any>>(get_url, httpOptions);
  }
}
