import { Router } from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import {AppConfig} from './app.config';
import 'rxjs/add/operator/do';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from './pages/login/login.service';
import { LoadingService } from './components/loading/loading.service';


@Injectable()
export class AppInterceptor implements HttpInterceptor {
  config;

  constructor(
      private router: Router,
      appConfig: AppConfig,
      private toastr: ToastrService,
      private loginService: LoginService,
      private loadingService: LoadingService,
  ) {
    this.config = appConfig.getConfig();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({url: this.config.baseURLApi + req.url});

    const token: string = localStorage.getItem('custAccessToken');
    if (token) {
      req = req.clone({
        setHeaders: {
          authorization: token
        }
      });

      // req = req.clone({
      //           setHeaders: {
      //               authorization: `Basic YWRtaW46YWRtaW5AMTIz`
      //           }
      // });


    }

    return next.handle(req).do((event: HttpEvent<any>) => {
      this.loadingService.show();
      if (event instanceof HttpResponse) {
        // do stuff with response if you want
        this.loadingService.hide();
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401 || err.status=== 403) {
          if(err.error.responseMessage === 'Token Expired'){
        
            this.loginService.logoutUser();
            this.toastr.error("Session has expired Please login again!");
          }
          this.router.navigate(['/login']);
        }
        this.loadingService.hide();
      }
    });
  }
}
