import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginInfo } from './models/login-info';
import { Observable } from 'rxjs';
import { JwtResponse } from './models/jwt-response';
import { TokenStorageService } from './token-storage-service';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private httpClient: HttpClient, private tokenService: TokenStorageService) { }

  login(loginData: LoginInfo): Observable<JwtResponse> {
    return this.httpClient.post<JwtResponse>("login", loginData, httpOptions);
  }

  logout() {
    this.tokenService.signOut();
  }
}
