import { Routes } from '@angular/router';
import { ErrorComponent } from './pages/error/error.component';
import {AppGuard} from './app.guard';
import { PaymentMidpageComponent } from './components/payment-midpage/payment-midpage.component';
import { PaymentStatusComponent } from './components/payment-status/payment-status.component';
import { PaymentLinkComponent } from './components/payment-link/payment-link.component';

export const ROUTES: Routes = [
  {
   path: '', canActivate: [AppGuard],   loadChildren: './layout/layout.module#LayoutModule'
  },
  {path: 'recharge/payment-status', component: PaymentStatusComponent},
  {
   path: 'redirecttopayment/:id',component:PaymentMidpageComponent 
  },
  { path:'pay/:user',component:PaymentLinkComponent
  },
  {
    path: 'login', loadChildren: './pages/login/login.module#LoginModule'
  },
  {
    path: 'register', loadChildren: './pages/register/register.module#RegisterModule'
  },
  {
    path: '', loadChildren: './pages/reset-password/reset-password.module#ResetPasswordModule',
  },
  {
    path: 'error', component: ErrorComponent
  },
  {
    path: '**',    component: ErrorComponent
  }
];
