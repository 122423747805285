import { Component, OnInit ,OnDestroy } from '@angular/core';
import * as alertify from 'alertifyjs';
import {interval, Observable, Subscription } from 'rxjs';
import 'rxjs/add/observable/timer';
import { ActivatedRoute, Router } from '@angular/router';
import { RechargeService } from '../../pages/recharge/recharge.service';
import { environment } from '../../../environments/environment';
import { AppConfig } from '../../../../src/app/app.config';

@Component({
  selector: 'payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss']
})
export class PaymentStatusComponent implements OnInit,OnDestroy {
 data:any;
  isLoading : boolean = false;

  loaderTimeInMill : number = 60000;
  redirectime:  number =10000;

  isSuccessful : boolean = false;

  isFailed : boolean = false;

  private intervalSub: Subscription;

  tempRun : boolean = true;

  paymentStatus : any = '';

  private subscription: Subscription;

  private timer: Observable<any>;
customerLogo:any
backEndImage:string;
  BackendFinalurl:string;
  constructor(
    private activatedRoute : ActivatedRoute,
    private router: Router,
    private rechargeService : RechargeService,
    private appConfig: AppConfig
    ) {
      this.backEndImage=this.appConfig.config.baseURLApi;
      // this.backEndImage=this.appConfig.imgbaseURL;
      this.BackendFinalurl=this.backEndImage.split('/')[2];
      
     }

  ngOnInit() {
    this.rechargeService.getCustomerlogo().subscribe((res)=>{
      this.customerLogo = environment.baseurlDev + res.data
          })
    const txnid = this.activatedRoute.snapshot.queryParamMap.get('txnid')    
    if(txnid != undefined && txnid !== '' && txnid !== null){
      this.getPurchaseDetailStatusByTransactionId(txnid);
      if(this.tempRun == true){
        this.setTimer(txnid);
      }
    }
  }
autoRedirect(){
  this.router.navigate(['../dashboard']);
}

  getPurchaseDetailStatusByTransactionId(transactionId) {
    this.rechargeService.getPurchaseDetailStatusByTransactionId(transactionId).subscribe((result) => {
      if(result){    
        if(result.responseCode == 200){
            this.tempRun = false;
            //console.log("result",result.data);   
            this.data = result.data;
           
            if(result.data.paymentstatus === 'Pending'){
              this.paymentStatus = result.data.paymentstatus;
            }
            if(result.data.paymentstatus === 'Successful'){
              this.paymentStatus = result.data.paymentstatus;
              this.isSuccessful = true;
              this.isLoading = false;
              this.cancelInterval();
              this.subscription.unsubscribe();
            }
            if(result.data.paymentstatus === 'Failed'){
              this.paymentStatus = result.data.paymentstatus;
              this.isFailed = true;
              this.isLoading = false;
              this.cancelInterval();
              this.subscription.unsubscribe();
            }     
            if(this.data){
              setTimeout(() => {
                this.autoRedirect()
              }, this.redirectime);
            }            
        }
      }},err => {
        alertify.error(err.error.status + " " + err.error.ERROR);
      });
  }

  public setTimer(transactionId){
      this.isLoading   = true;
      this.setInterval(10000,transactionId);
      this.timer = Observable.timer(this.loaderTimeInMill);
      this.subscription = this.timer.subscribe(() => {
        this.isLoading = false;
        this.cancelInterval();
        this.subscription.unsubscribe();
      });
  }

  public setInterval(millisecond,transactionId){
      this.intervalSub = interval(millisecond).subscribe(val => 
        this.getPurchaseDetailStatusByTransactionId(transactionId)
      );
  }

  cancelInterval() {
    if (this.intervalSub)
      this.intervalSub.unsubscribe();
  }

  public ngOnDestroy() {
    this.cancelInterval();
    if ( this.subscription && this.subscription instanceof Subscription) {
      this.subscription.unsubscribe();
    }
  }

}
